<template>
    <div class="leaflet-control text-center" style="max-width: 500px;">
      
      <b-button v-if="!visible_legend" @click="visible_legend = !visible_legend" class="m-1" variant="outline-light" style="border-radius: 15px;"><i  class="fa-solid fa-circle-info"></i></b-button>
      <b-button v-if="visible_legend" @click="visible_legend = !visible_legend" class="m-1" variant="outline-danger" style="border-radius: 15px;"><i class="fa-solid fa-xmark"></i></b-button>
        <b-collapse  v-model="visible_legend" class="mt-2">
          
        <div class="" style="max-height: 300px;overflow-y: scroll;">
        <div class="" >
            <div class="text-center d-flex justify-content-center"> 
              <strong class="mb-2" style="font-size:16px" v-html="'wpoints'"></strong>
            </div>
            <div v-for="(ll,index) in legend_points" :key="index+'legend'">
              <div class="d-flex px-4 align-items-center" >
                <div v-if="ll.pointSymbolizer" v-html="ll.pointSymbolizer.icon.options.html"></div>
                <p class="px-2 m-0" v-if="ll.filter" style="font-size: 12px;text-align: left;">{{ ll.filter.comparisions[0].literal }}</p>
                
              </div>
              <div >

              </div>
            </div>
            
        </div> 
        <div class="" >
          <div class="text-center d-flex justify-content-center"> 
            <strong class="mb-2" style="font-size:16px" v-html="'wpipe'"></strong>
          </div>
            <div v-for="(hh,index) in legend_pipe" :key="index+'legend'">
              <div class="d-flex px-4 align-items-center" v-if="index%2">
                <div class="col-2 p-0">
                  <hr style="width:15px;border: 3px solid;text-align:left;margin-left:0" :style="{color: `rgba(${hh.symbol.color})`}"  >
                </div>
                <p class="px-2 m-0" style="font-size: 12px;text-align: left;">
                  <span>{{ hh.label }}</span>
                </p>
                
              </div>
              <div >

              </div>
            </div>
            
        </div>  
       
        </div>  
      </b-collapse>
        
    </div>
</template>


<script>
export default {
  props: {
    data: Object
  },
data(){
    return{
        visible_legend:true,
        legend_data_value:null,
        legend_data_col:null,
        title:null,
        array_not_show:['text','value','in_radio_group'],
        legend_pipe:[],
        legend_points:[]
    }
},
  created(){
    console.log(this.data)
    this.legend_points=this.data['points'].featureTypeStyles[0].rules
    this.legend_pipe=this.data['pipe']
    //this.legend_data_color=this.data.color
    //this.legend_data_value=this.data.value
    //this.title=this.data.title
  }
}

</script>

<style >
.box3 {
    
    padding:8px
    /* height: 22px; */
}
@media screen and (max-width: 730px) {
  .box3 {
    font-size: 14px!important;
    width: 40px;
    /* height: 22px; */
}
}
</style>