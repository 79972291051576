import { render, staticRenderFns } from "./legendForSLD.vue?vue&type=template&id=04f0539f"
import script from "./legendForSLD.vue?vue&type=script&lang=js"
export * from "./legendForSLD.vue?vue&type=script&lang=js"
import style0 from "./legendForSLD.vue?vue&type=style&index=0&id=04f0539f&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports